module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "conversations-embed": "static-1.21240",
      "conversations-internal-schema": "static-1.10116",
      "head-dlb": "static-1.2378",
      "hs-test-utils": "static-1.5458",
      "hubspot-url-utils": "static-1.1962",
      "HubStyleTokens": "static-2.9007",
      "I18n": "static-7.1301",
      "jasmine-runner": "static-1.3829",
      "quartz": "static-1.3981",
      "react": "static-7.133",
      "sinon": "static-1.9",
      "webpack-env": "static-1.41",
      "isMobile": "static-1.7",
      "StyleGuideUI": "static-3.432",
      "enviro": "static-4.271",
      "hs-promise-rejection-tracking": "static-1.2751",
      "PortalIdParser": "static-2.250",
      "raven": "static-3.3055",
      "raven-hubspot": "static-1.3362",
      "bend-plugin-trellis-migration": "static-1.1033",
      "moment": "static-3.26",
      "immutable": "static-2.19",
      "transmute": "static-2.29",
      "HeadJS": "static-2.517",
      "react-dom": "static-7.85",
      "foundations-components": "static-1.4119",
      "foundations-theming": "static-1.1329",
      "framer-motion": "static-1.29",
      "hs-test-utils-bend-plugin": "static-1.569",
      "hub-http": "static-1.2732",
      "hub-http-janus": "static-1.585",
      "hub-http-rxjs": "static-1.550",
      "msw": "static-1.39",
      "react-redux": "static-7.16",
      "react-utils": "static-2.3143",
      "redux": "static-4.16",
      "redux-mock-store": "static-1.8",
      "redux-thunk": "static-2.17",
      "testing-library": "static-1.112",
      "UIComponents": "static-3.5957",
      "i18n-data": "static-1.187",
      "metrics-js": "static-1.7028",
      "moment-timezone": "static-5.17",
      "jasmine": "static-4.1736",
      "quartz-core-utils": "static-1.848",
      "csstype": "static-1.8",
      "bend-plugin-foundations-components": "static-1.387",
      "floating-ui": "static-1.19",
      "foundations-assets": "static-1.2226",
      "hs-story-utils": "static-1.6419",
      "react-aria": "static-1.31",
      "react-select-plus": "static-1.65",
      "react-virtualized": "static-2.66",
      "styled-components": "static-2.40",
      "tanstack-table": "static-1.10",
      "ui-fonts": "static-1.327",
      "ui-images": "static-2.765",
      "foundations-theming-base": "static-1.314",
      "rxjs": "static-5.10",
      "hoist-non-react-statics": "static-3.9",
      "classnames": "static-2.10",
      "cssUtils": "static-1.320",
      "HubStyle": "static-2.9317",
      "icons": "static-2.585",
      "react-input-autosize": "static-2.17",
      "sassPrefix": "static-1.121"
    },
    "depPathPrefixes": {
      "conversations-embed": "/conversations-embed/static-1.21240",
      "conversations-internal-schema": "/conversations-internal-schema/static-1.10116",
      "head-dlb": "/head-dlb/static-1.2378",
      "hs-test-utils": "/hs-test-utils/static-1.5458",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1962",
      "HubStyleTokens": "/HubStyleTokens/static-2.9007",
      "I18n": "/I18n/static-7.1301",
      "jasmine-runner": "/jasmine-runner/static-1.3829",
      "quartz": "/quartz/static-1.3981",
      "react": "/react/static-7.133",
      "sinon": "/sinon/static-1.9",
      "webpack-env": "/webpack-env/static-1.41",
      "isMobile": "/isMobile/static-1.7",
      "StyleGuideUI": "/StyleGuideUI/static-3.432",
      "enviro": "/enviro/static-4.271",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2751",
      "PortalIdParser": "/PortalIdParser/static-2.250",
      "raven": "/raven/static-3.3055",
      "raven-hubspot": "/raven-hubspot/static-1.3362",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.1033",
      "moment": "/moment/static-3.26",
      "immutable": "/immutable/static-2.19",
      "transmute": "/transmute/static-2.29",
      "HeadJS": "/HeadJS/static-2.517",
      "react-dom": "/react-dom/static-7.85",
      "foundations-components": "/foundations-components/static-1.4119",
      "foundations-theming": "/foundations-theming/static-1.1329",
      "framer-motion": "/framer-motion/static-1.29",
      "hs-test-utils-bend-plugin": "/hs-test-utils-bend-plugin/static-1.569",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-janus": "/hub-http-janus/static-1.585",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.550",
      "msw": "/msw/static-1.39",
      "react-redux": "/react-redux/static-7.16",
      "react-utils": "/react-utils/static-2.3143",
      "redux": "/redux/static-4.16",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "redux-thunk": "/redux-thunk/static-2.17",
      "testing-library": "/testing-library/static-1.112",
      "UIComponents": "/UIComponents/static-3.5957",
      "i18n-data": "/i18n-data/static-1.187",
      "metrics-js": "/metrics-js/static-1.7028",
      "moment-timezone": "/moment-timezone/static-5.17",
      "jasmine": "/jasmine/static-4.1736",
      "quartz-core-utils": "/quartz-core-utils/static-1.848",
      "csstype": "/csstype/static-1.8",
      "bend-plugin-foundations-components": "/bend-plugin-foundations-components/static-1.387",
      "floating-ui": "/floating-ui/static-1.19",
      "foundations-assets": "/foundations-assets/static-1.2226",
      "hs-story-utils": "/hs-story-utils/static-1.6419",
      "react-aria": "/react-aria/static-1.31",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-virtualized": "/react-virtualized/static-2.66",
      "styled-components": "/styled-components/static-2.40",
      "tanstack-table": "/tanstack-table/static-1.10",
      "ui-fonts": "/ui-fonts/static-1.327",
      "ui-images": "/ui-images/static-2.765",
      "foundations-theming-base": "/foundations-theming-base/static-1.314",
      "rxjs": "/rxjs/static-5.10",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "classnames": "/classnames/static-2.10",
      "cssUtils": "/cssUtils/static-1.320",
      "HubStyle": "/HubStyle/static-2.9317",
      "icons": "/icons/static-2.585",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "sassPrefix": "/sassPrefix/static-1.121"
    },
    "project": "conversations-embed",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};