module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "conversations-embed": "static-1.20423",
      "conversations-internal-schema": "static-1.9699",
      "head-dlb": "static-1.2040",
      "hs-test-utils": "static-1.4866",
      "hubspot-url-utils": "static-1.1575",
      "HubStyleTokens": "static-2.8407",
      "I18n": "static-7.1253",
      "jasmine-runner": "static-1.3320",
      "quartz": "static-1.3510",
      "react": "static-7.133",
      "sinon": "static-1.9",
      "webpack-env": "static-1.41",
      "isMobile": "static-1.7",
      "StyleGuideUI": "static-3.421",
      "enviro": "static-4.260",
      "hs-promise-rejection-tracking": "static-1.2371",
      "PortalIdParser": "static-2.239",
      "raven": "static-3.2477",
      "raven-hubspot": "static-1.2784",
      "bend-plugin-trellis-migration": "static-1.539",
      "moment": "static-3.26",
      "immutable": "static-2.19",
      "transmute": "static-2.29",
      "HeadJS": "static-2.493",
      "react-dom": "static-7.85",
      "foundations-components": "static-1.3215",
      "foundations-theming": "static-1.887",
      "hub-http": "static-1.2732",
      "hub-http-janus": "static-1.559",
      "hub-http-rxjs": "static-1.525",
      "msw": "static-1.39",
      "react-redux": "static-7.16",
      "react-utils": "static-2.2773",
      "redux": "static-4.16",
      "redux-mock-store": "static-1.8",
      "redux-thunk": "static-2.17",
      "testing-library": "static-1.109",
      "UIComponents": "static-3.5357",
      "i18n-data": "static-1.183",
      "metrics-js": "static-1.6348",
      "moment-timezone": "static-5.17",
      "jasmine": "static-4.1404",
      "browserslist-config-hubspot": "static-1.134",
      "quartz-core-utils": "static-1.377",
      "csstype": "static-1.8",
      "hoist-non-react-statics": "static-3.9",
      "rxjs": "static-5.10",
      "styled-components": "static-2.40",
      "classnames": "static-2.10",
      "cssUtils": "static-1.315",
      "hs-story-utils": "static-1.5815",
      "HubStyle": "static-2.8717",
      "icons": "static-2.583",
      "react-select-plus": "static-1.65",
      "react-virtualized": "static-2.63",
      "ui-images": "static-2.747",
      "sassPrefix": "static-1.116",
      "ui-fonts": "static-1.326",
      "react-input-autosize": "static-2.17",
      "framer-motion": "static-1.26",
      "floating-ui": "static-1.15",
      "foundations-assets": "static-1.1785",
      "react-aria": "static-1.7",
      "tanstack-table": "static-1.10"
    },
    "depPathPrefixes": {
      "conversations-embed": "/conversations-embed/static-1.20423",
      "conversations-internal-schema": "/conversations-internal-schema/static-1.9699",
      "head-dlb": "/head-dlb/static-1.2040",
      "hs-test-utils": "/hs-test-utils/static-1.4866",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.1575",
      "HubStyleTokens": "/HubStyleTokens/static-2.8407",
      "I18n": "/I18n/static-7.1253",
      "jasmine-runner": "/jasmine-runner/static-1.3320",
      "quartz": "/quartz/static-1.3510",
      "react": "/react/static-7.133",
      "sinon": "/sinon/static-1.9",
      "webpack-env": "/webpack-env/static-1.41",
      "isMobile": "/isMobile/static-1.7",
      "StyleGuideUI": "/StyleGuideUI/static-3.421",
      "enviro": "/enviro/static-4.260",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.2371",
      "PortalIdParser": "/PortalIdParser/static-2.239",
      "raven": "/raven/static-3.2477",
      "raven-hubspot": "/raven-hubspot/static-1.2784",
      "bend-plugin-trellis-migration": "/bend-plugin-trellis-migration/static-1.539",
      "moment": "/moment/static-3.26",
      "immutable": "/immutable/static-2.19",
      "transmute": "/transmute/static-2.29",
      "HeadJS": "/HeadJS/static-2.493",
      "react-dom": "/react-dom/static-7.85",
      "foundations-components": "/foundations-components/static-1.3215",
      "foundations-theming": "/foundations-theming/static-1.887",
      "hub-http": "/hub-http/static-1.2732",
      "hub-http-janus": "/hub-http-janus/static-1.559",
      "hub-http-rxjs": "/hub-http-rxjs/static-1.525",
      "msw": "/msw/static-1.39",
      "react-redux": "/react-redux/static-7.16",
      "react-utils": "/react-utils/static-2.2773",
      "redux": "/redux/static-4.16",
      "redux-mock-store": "/redux-mock-store/static-1.8",
      "redux-thunk": "/redux-thunk/static-2.17",
      "testing-library": "/testing-library/static-1.109",
      "UIComponents": "/UIComponents/static-3.5357",
      "i18n-data": "/i18n-data/static-1.183",
      "metrics-js": "/metrics-js/static-1.6348",
      "moment-timezone": "/moment-timezone/static-5.17",
      "jasmine": "/jasmine/static-4.1404",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.134",
      "quartz-core-utils": "/quartz-core-utils/static-1.377",
      "csstype": "/csstype/static-1.8",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "rxjs": "/rxjs/static-5.10",
      "styled-components": "/styled-components/static-2.40",
      "classnames": "/classnames/static-2.10",
      "cssUtils": "/cssUtils/static-1.315",
      "hs-story-utils": "/hs-story-utils/static-1.5815",
      "HubStyle": "/HubStyle/static-2.8717",
      "icons": "/icons/static-2.583",
      "react-select-plus": "/react-select-plus/static-1.65",
      "react-virtualized": "/react-virtualized/static-2.63",
      "ui-images": "/ui-images/static-2.747",
      "sassPrefix": "/sassPrefix/static-1.116",
      "ui-fonts": "/ui-fonts/static-1.326",
      "react-input-autosize": "/react-input-autosize/static-2.17",
      "framer-motion": "/framer-motion/static-1.26",
      "floating-ui": "/floating-ui/static-1.15",
      "foundations-assets": "/foundations-assets/static-1.1785",
      "react-aria": "/react-aria/static-1.7",
      "tanstack-table": "/tanstack-table/static-1.10"
    },
    "project": "conversations-embed",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};